<template>
	<div>
		<el-drawer
				v-model="userStore.userCenterDrawerShow"
				direction="rtl"
				:size="drawerSize">
			<!-- 头部 -->
			<template #header="{ close, titleId, titleClass }">
				<div class="titleBox">
					<div class="logoBox">
						<img style="height: 40px;width: 160px " src="/images/logo/logoNew.png" alt="logo"/>
					</div>
					<div class="headerInfoBox">
						<div class="imgBox">
							<el-upload
									class="avatar-uploader"
									:action="baseurl + '/file/upload'"
									:show-file-list="false"
									multiple
									:on-success="handleHeaderUploadSuccess"
									:on-error="handleHeaderUploadError"
									:before-upload="beforeUpload">
								<img v-if="formData.header" :src="formData.header" class="avatar" style="height: 50px;width: 50px;border-radius"
								     alt="品牌logo"/>
								<el-icon style="height: 50px;width: 50px;border-radius: 50%" v-else class="avatar-uploader-icon">
									<Plus/>
								</el-icon>
							</el-upload>
						</div>
						<div>
							<div>{{ formData.name }}</div>
							<div class="signClass">{{ formData.sign }}</div>
						</div>
					</div>
				</div>
			</template>
			<el-scrollbar>
				<div class="centotBody">
					<div class="bodyMain">
						<div class="mainleft">
							<div class="buttonItem" v-for="(item,index) in manuList" :key="index"
							     @click="manuClick(manuList,item,index)">
								<div :class="{active: item.selected}">{{ item.btnname }}</div>
							</div>
						</div>
						<div class="mainright">
							<div class="infoItem" v-if="indexNum === 0">
								<div class="myinfomation">
									<el-form :model="formData"
									         :height="mainHeight"
									         label-width="auto"
									         style="width: 100%">
										<el-form-item label="昵称">
											<el-input v-model="formData.nickName"/>
										</el-form-item>
										<el-form-item label="会员等级">
											<el-input v-model="formData.levelId"/>
										</el-form-item>
										<el-form-item label="手机">
											<el-input v-model="formData.phone"/>
										</el-form-item>
										<el-form-item label="邮箱">
											<el-input v-model="formData.email"/>
										</el-form-item>
										<el-form-item label="积分">
											<el-input v-model="formData.integreat"/>
										</el-form-item>
										<el-form-item label="成长值">
											<el-input v-model="formData.growth"/>
										</el-form-item>
										<el-form-item label="所在城市">
											<el-input v-model="formData.city"/>
										</el-form-item>
										<el-form-item label="出生日期">
											<el-col :span="11">
												<el-date-picker
														v-model="birth"
														show-today
														placeholder="选择出生日期"
														style="width: 100%"
												/>
											</el-col>
										</el-form-item>
										<el-form-item label="性别">
											<el-radio-group v-model="formData.genders">
												<el-radio :label=1>男</el-radio>
												<el-radio :label=0>女</el-radio>
											</el-radio-group>
										</el-form-item>
										<el-form-item label="个性签名">
											<el-input v-model="formData.sign" type="textarea"/>
										</el-form-item>
									</el-form>
								</div>
								<div class="submitbtn">
									<el-button type="primary" @click="onSubmit">提交</el-button>
								</div>
							</div>
							<div class="infoItem" v-if="indexNum === 1">
								<el-table
										:height="mainHeight"
										:data="tableData">
									<el-table-column property="date" label="Date" width="150"/>
									<el-table-column property="name" label="Name" width="200"/>
									<el-table-column property="address" label="Address"/>
								</el-table>
							</div>
							<div class="infoItem" v-if="indexNum === 2">
								<el-table
										:height="mainHeight"
										:data="tableData">
									<el-table-column property="date" label="Date" width="150"/>
									<el-table-column property="name" label="Name" width="200"/>
									<el-table-column property="address" label="Address"/>
								</el-table>
							</div>
							<div class="infoItem" v-if="indexNum === 3">
								<el-table
										:height="mainHeight"
										:data="tableData">
									<el-table-column property="date" label="Date" width="150"/>
									<el-table-column property="name" label="Name" width="200"/>
									<el-table-column property="address" label="Address"/>
								</el-table>
							</div>
							<!-- address -->
							<div class="infoItem" v-if="indexNum === 4">
								<el-table
										:height="mainHeight"
										:data="addressData">
									<el-table-column prop="name" label="name"/>
									<el-table-column prop="phone" label="phone"/>
									<el-table-column prop="postCode" label="postCode"/>
									<el-table-column prop="province" label="province"/>
									<el-table-column prop="city" label="city"/>
									<el-table-column prop="town" label="town"/>
									<el-table-column prop="detailAddress" label="detailAddress"/>
									<el-table-column prop="areaCode" label="area_code"/>
									<el-table-column prop="defaultStatus" label="defaultStatus">
										<template #default="scope">
											<el-tag type="success" v-if="scope.row.defaultStatus === 0">未设置默认</el-tag>
											<el-tag type="success" v-if="scope.row.defaultStatus === 1">已设为默认</el-tag>
										</template>
									</el-table-column>
									<el-table-column align="center">
										<template #header>
											<el-button @click="insertAddress" type="primary">添加地址</el-button>
										</template>
										<template #default="scope">
											<el-button type="success" size="small" @click="editBtn(scope.row,scope,index)">
												<el-icon>
													<edit/>
												</el-icon>
											</el-button>
											<el-button type="danger" size="small" @click="deleteBtn(scope.row,scope,index)">
												<el-icon>
													<delete/>
												</el-icon>
											</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<!-- orderList -->
							<div class="infoItem" v-if="indexNum === 5">
								<el-table
										:height="mainHeight"
										:data="tableData"
								>
									<el-table-column prop="orderSn" label="订单编号" width="350px" align="center"/>
									<el-table-column prop="phone" label="phone"/>
									<el-table-column prop="post_code" label="post_code"/>
									<el-table-column prop="province" label="province"/>
									<el-table-column prop="city" label="city"/>
									<el-table-column prop="region" label="region"/>
									<el-table-column prop="detail_address" label="detail_address"/>
									<el-table-column prop="area_code" label="area_code"/>
									<el-table-column prop="default_status" label="default_status"/>
								</el-table>
							</div>
						</div>
					</div>
				</div>
			</el-scrollbar>

		</el-drawer>

		<div>
			<add_edit_address-dialog @refreshAddress="refreshAddress" :sendData="sendData"/>
		</div>


	</div>

</template>

<script lang="ts" setup>
import {onMounted, reactive, ref} from "vue";
import userUserStore from "@/stores/user";
import Router from "@/router"
import {ElMessageBox, ElMessage} from "element-plus";
import {deleteMemberAddress_req, getOrderList_req} from "@/axiosAPI/oms/oms.js";
import {updataMemberInfo_req, getAddress_req} from '@/axiosAPI/member/memberManage.js'
import Add_edit_addressDialog from "@/pages/member/add_edit_addressDialog.vue";

defineOptions({
	name: "memberInfo",
	inheritAttrs: false
})
let baseurl = process.env.VUE_APP_BASE_URL //图片上传路径设置
let mainHeight = ref()
let px = ref("px")
let birth = ref((new Date()))
let drawerSize = ref()
let sizeUnit = ref("%")
let params = {
	currentPage: 1,
	pageSize: 5,
	sort: 'desc',
	paymentTime: null,
	status: null
}
setInterval(() => {
	mainHeight.value = window.innerHeight - 120 + px.value
})
let formData = reactive({
	id: null,
	token: null,
	redirect: null,
	name: null,
	password: null,
	phone: null,
	levelId: null,
	nickName: null,
	email: null,
	header: null,
	genders: null,
	birth: new Date(),
	city: null,
	job: null,
	sign: null,
	sourceType: null,
	integreat: null,
	growth: null,
	status: null,
	createTime: null,
	socialUid: null,
	accessToken: null,
	expoiresIn: null,
})
const addressData = reactive([
	{
		id: null,
		memberId: null,
		name: null,
		phone: null,
		postCode: null,
		province: null,
		city: null,
		town: null,
		detailAddress: null,
		areaCode: null,
		defaultStatus: null,
		isDelete: null,
		createTime: null,
		updateTime: null,
	}
])
let sendData = ref(null)
let tabPosition = ref('left')
let userStore = userUserStore()
let table = ref(false)
let tableData = ref()
let indexNum = ref(0)
onMounted(() => {
	let info = JSON.parse(localStorage.getItem("userInfo"));
	Object.assign(formData, info)
	getaddress()
})
let manuList = ref([
	{btnname: "个人信息", selected: true},
	{btnname: "账号设置", selected: false},
	{btnname: "我的钱包", selected: false},
	{btnname: "我的服务", selected: false},
	{btnname: "我的地址", selected: false},
	{btnname: "我的订单", selected: false},
])

// defineProps(['refreshAddress'])
function manuClick(list, item, index) {
	if (index === 0) {
		Object.assign(formData, userStore.userInfo)
	}
	if (index === 4 || index === 5) {
		drawerSize.value = 100 + sizeUnit.value
	} else {
		drawerSize.value = 50 + sizeUnit.value
	}
	if (index === 4) {
		getaddress()
	}
	if (index === 5) {
		// getOrderList()
		Router.push("/orderList")
	}
	indexNum.value = index
	list.forEach(i => {
		i.selected = false
	})
	item.selected = true
}

function refreshAddress() {
	getaddress()
}

function onSubmit() {
	ElMessageBox.confirm("确定提交设置吗").then(() => {
		updataMemberInfo()
		ElMessage("提交成功")
	}).catch((e) => {
		ElMessage(e)
	})

}

function insertAddress() {
	userStore.addressDialogShow = true
	userStore.addressDialogTitle = "地址添加界面"
	sendData.value = formData.id
}

function editBtn(row, index) {
	userStore.addressDialogShow = true
	userStore.addressDialogTitle = "地址编辑界面"
	sendData.value = row
}

function deleteBtn(row, index) {
	ElMessageBox.confirm("确定删除这条地址吗？").then(() => {
		deleteMemberAddress(row)
	}).catch((e) => {
		ElMessage(e)
	})

}

async function deleteMemberAddress(row) {
	await deleteMemberAddress_req(row)
	ElMessage("删除成功！")
	getaddress()
}

async function updataMemberInfo() {

	formData.birth = birth.value

	await updataMemberInfo_req(formData)

}

function getaddress() {
	addressGetAndUpdata()
}

async function addressGetAndUpdata() {
	let data = {
		memberId: null
	}
	data.memberId = formData.id
	let res = await getAddress_req(data)
	Object.assign(addressData, res.data.data)
}

async function getOrderList() {
	let res = await getOrderList_req(params)
	tableData.value = res.data.data
}


//logo上传
const handleHeaderUploadSuccess = (res, file) => {
	if (res.code == 200) {
		formData.header = res.data
		updataMemberInfo()
	} else {
		ElMessage.error("请求发送，但没有成功！")
	}
}
const handleHeaderUploadError = (res, file) => {
	ElMessage.error("上传失败！" + res.message)
}
const beforeUpload = (file) => {
	let isjpg = file.type === 'image/jpg' || 'image/jpeg'
	let ispng = file.type === 'image/png'
	let israng = file.size / 1024 / 1024 < 2

	if (!isjpg && !ispng) {
		ElMessage.error("图片格式请确保是jpg 或png 格式")
	}
	if (!israng) {
		ElMessage.error("图片大小不得大于2M")
	}
}


</script>

<style scoped>
.myinfomation {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.submitbtn {
	display: flex;
	width: 100%;
	justify-content: center;
}

.signClass {
	font: 300 14px 微软雅黑;
	margin-top: 5px;
}

.infoItem {
	width: 100%;
	height: 100%;
	padding: 0 0 0 10px;
	box-sizing: border-box;
}

.active {
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 40px;
	width: 100%;
	color: rgba(255, 0, 0, 0.8);
}

.buttonItem {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 180px;
	cursor: pointer;
	margin: 1px 0;
}

.buttonItem:hover {
	color: rgba(255, 0, 0, 0.3);
}

.imgBox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	/*border-radius: 50%;*/
	/*background: black;*/
	margin-right: 10px;
}

.headerInfoBox {
	display: flex;
	width: 100%;
	align-items: center;

}

.bodyMain {
	display: flex;
	height: 100%;
	padding-right: 10px;
	box-sizing: border-box;
	background: rgba(200, 200, 200, 0.4);
	border-bottom: 2px solid red;
	width: 100%;
}

.mainleft {
	width: 200px;
	height: 100%;
}

.mainright {
	border-left: 2px solid red;
	width: 100%;
	height: 100%;
}

.centotBody {
	border-top: 2px solid red;
	width: 100%;
	margin-right: 20px;: height: mainHeight;
}

.logoBox {
	display: flex;
	margin: 0 10px;
	justify-content: center;
	width: 200px;
}

.titleBox {
	/*margin-top: 33px;*/
	/*height: 10px;*/
	justify-content: space-between;
	display: flex;
	align-items: center;
}

::v-deep(.el-tabs__item) {
	padding: 0 80px;
	height: var(--el-tabs-header-height);
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	font-size: var(--el-font-size-base);
	font-weight: 500;
	color: var(--el-text-color-primary);
	position: relative;
}

::v-deep(.el-form) {
	--el-form-label-font-size: var(--el-font-size-base);
	--el-form-inline-content-width: 100%;
}

/**
.el-drawer__header {
    align-items: center;
    color: #72767b;
    display: flex;
    margin-bottom: 32px;
    padding: var(--el-drawer-padding-primary);
    padding-bottom: 0;
}
 */
::v-deep(.el-drawer__header) {
	margin-bottom: 0;
}
</style>