//路由鉴权，全局守卫

//引入路由
import router from "@/router";
import Nprogress from 'nprogress'
import "nprogress/nprogress.css"
import {getToken} from '@/utils/auth.js'
import {ElMessage} from "element-plus";

//未登录可以访问的路由路径
let routerViewFree =['/FastHelpHome','/login','/register']

router.beforeEach( (to,from,next)=>{

    //加载进度条
    Nprogress.start();
    //动态添加标题
    document.title= `unitim - ${to.meta.title}`

    const hasToken = getToken()
    if (hasToken){
        next();
    } else {
        if (routerViewFree.includes(to.path)) {
            next();
        }else{
            next({path:'/login',query:{redirect:to.fullPath}})
            ElMessage("完成注册登录后才可以访问！")
        }
    }
})

router.afterEach((to,from)=>{
    Nprogress.done();
})

