import request from "@/utils/request";


const  BASE_URL  = process.env.VUE_APP_SERVER_PATH

/**
 * 添加信息
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
export const AddAttrSubmitFunction_req = (data) =>{
    return request.post('/pms-attr/addInfo',{
        attrId:data.attrId,
        attrName:data.attrName,
        searchType:data.searchType,
        attrType:data.attrType,
        valueType:data.valueType,
        icon:data.icon,
        valueSelect:data.valueSelects.toString(),
        enable:data.enable,
        catelogId:data.catelogPath[data.catelogPath.length-1],
        showDesc:data.showDesc,
        catelogPath:data.catelogPath,
        attrGroupId:data.attrGroupId
    })
}

/**
 * 属性列表获取
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getAttrListInterface = (data) =>{
    return request.post( '/pms-attr/getAttrList',
        {
            // id = catelogId 根据分类id 查询所有该类下的属性列表
                id:data.id,
                currentPage:data.currentPage,
                pageSize :data.pageSize

        }

    )
}


/**
 * 获取属性列表 catelogId key 等多属性查询
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const getAttrByidInterface = (data) =>{
    console.log("请求已发送：",data)
    return request.get( `/pms-attr/getattrByid/${data.catelogId}`,{
        params:{
           "id":data.id,
            "attrType":data.attrType,
           "catelogId":data.catelogId,
           "currentPage":data.currentPage,
           "pageSize" :data.pageSize,
            "key":data.key,
    }
        })
}







/**
 * 编辑信息
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
export const EditAttrSubmitFunction_req = (data) =>{
    return request.post('/pms-attr/EditAttrInfo',{
        attrId:data.attrId,
        attrName:data.attrName,
        searchType:data.searchType,
        attrType:data.attrType,
        valueType:data.valueType,
        icon:data.icon,
        valueSelect:data.valueSelect,
        enable:data.enable,
        catelogId:data.catelogPath[data.catelogPath.length-1],
        showDesc:data.showDesc,
        catelogPath:[]
    })
}


export const getAttrEditRecordData =(data) =>{
    return request.post("/pms-attr/editRecordData",{
        attrId:data.attrId
    })
}


export const attrDelete = (data) =>{
    return request.post("/pms-attr/attrDelete",{
        attrId:data.attrId
    })
}


/**
 * 属性关联解绑界面的删除和批量删除功能
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const unBindAttrrelation_req = (data) => {
    return request.post(
        '/pms-attr-group/unBindAttrrelation',
        [{
            attrId: data.attrId,
            attrGroupId:data.attrGroupId,
        }])
}


