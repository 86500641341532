<template>
	<el-dialog
			:model-value="visible"
			:title="params.title"
			width="30%"
			center
			draggable
			:close-on-click-modal="false"
			destroy-on-close
			lock-scroll
			@closed="dislogclosed"
			close-on-press-escape
			:show-close="false">
    <span>


	    <!-- pinia 仓库数据 -->
	    <div>
		    <el-form
				    :rules="rules"
				    ref="ruleForm"
				    :model="attrGroupDataModel" label-width="100px">
	      <el-form-item label="分组名称" prop="attrGroupName">
		      <el-input v-model="attrGroupDataModel.attrGroupName" placeholder="请输入分组名称"></el-input>
	      </el-form-item>
	      <el-form-item label="分组图标" prop="icon">
		          <el-upload
				          class="avatar-uploader"
				          :action="BASE_URL + '/file/upload'"
				          :show-file-list="false"
				          multiple
				          :on-success="handleLogoUploadSuccess"
				          :on-error="handerLogoUploadError"
				          :before-upload="beforeLogoUpload">
				          <img v-if="attrGroupDataModel.icon" :src="attrGroupDataModel.icon" class="avatar"
				               style="height: 50px;width: 50px"/>
				          <el-icon style="height: 50px;width: 50px" v-else class="avatar-uploader-icon"><Plus/></el-icon>
               </el-upload>
	      </el-form-item>
	      <el-form-item label="分组描述" prop="descript">
		      <el-input v-model="attrGroupDataModel.descript" placeholder="请输入分组描述"></el-input>
	      </el-form-item>
	      <el-form-item label="分组排序" prop="sort">
		      <el-input-number v-model="attrGroupDataModel.sort" placeholder="请输入排序方式"></el-input-number>
	      </el-form-item>
	      <el-form-item label="分类ID" prop="catelogId">
		      <el-cascader
				      placeholder="试试搜索：如手机"
				      v-model="attrGroupDataModel.catelogPath"
				      :options="categores"
				      clearable
				      filterable
				      :props="props"/>
	      </el-form-item>
      </el-form>
	    </div>
    </span>
		<!--
		v-model="diaLogshow.currentEditValue.catelogPath"
		:value="diaLogshow.currentEditValue.catelogPath"
		-->
		<template #footer>
      <span class="dialog-footer">
        <el-button @click="cancelBtn"><el-icon><Close/></el-icon></el-button>
        <el-button type="primary" @click="confirmBtn(ruleForm)"><el-icon><Check/></el-icon></el-button>
      </span>
		</template>
	</el-dialog>
</template>
<script lang="ts" setup>
import useAttrGroupStore from "@/stores/attrGroupStore"
import {nextTick, onMounted, reactive, ref,defineEmits,defineProps} from "vue";
import {ElMessage, ElMessageBox, FormRules, FormInstance} from "element-plus";
import {addAttrGroupreq, attrGroupIdsearchreq, EditAttrGroupreq} from '@/axiosAPI/product/attrgroup.js'
import {category_backend_Req} from "@/axiosAPI/product/product.js";
import {editdisplayType} from "@/pages/DateType/attrGroupTypr";

const ruleForm = ref()
defineOptions({
	name: "attrgroup_and_OR_updata"
})
const attrGroupDataModel = reactive({
	attrGroupId: 0,
	attrGroupName: '',
	icon: '',
	descript: '',
	sort: 0,
	catelogPath: [],
	catelogId: 0
})
//取消按钮传递给父组件

let $emits = defineEmits(['attrGroupCancelBtn']['refreshDataList'])
//接收dialog显示接收
defineProps(['visible'])
//提交添加或修改后的刷新请求

let BASE_URL =process.env.VUE_APP_BASE_URL

//弹出框三级下拉菜单数据动态响应数据
let categores = ref([])

//弹出框三级下拉菜单显示属性设置
let props = reactive({
	//要发送的字段
	value: 'id',
	label: 'name',
	//子集合
	children: 'childer'
}) //参数不能定义为数组，否则不显示，只能定义对象


//添加或编辑完成请求父组件更新界面

//获取三级菜单
const getcategores = async () => {
	let res = await category_backend_Req()
	categores.value = res.data.data
}
//请求数据参数模板
const params = reactive({
	id: 0,
	attrId: 0,
	catelogId: 0,
	type: '',
	pageSize: 10,
	currentPage: 1,
	title: '',
	//排序字段
	sidx: '',
	//排序方式
	order: 'asc',
	key: ''
	//关键字
})


onMounted(() => {
	getcategores();
})
//清空catlogpath路径
const dislogclosed = () => {
	attrGroupDataModel.catelogPath = []
}
//添加确定按钮
const confirmBtn = (formEl: FormInstance | undefined) => {
	if (!formEl) {
		ElMessage.error("数据校验不通过，请检查后重试！")
		return
	} else {
		formEl.validate((valid) => {
			if (valid) {
				ElMessageBox.confirm(
						'确定要添加这条信息吗？',
						'warining',
						{
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
					try {
						//数据校验通过
						if (ValidityState)
								//判断添加还是修改操作
							if (params.type === 'edit') {
								await EditAttrGroup().then(() => {
									resetParam()
									$emits('refreshDataList',params.catelogId)
									ElMessage({type: 'success', message: "编辑修改成功！"})
								});
							} else {
								if (params.type === 'add') {
									await addAttrGroup().then(() => {
										$emits('refreshDataList',attrGroupDataModel.catelogPath[attrGroupDataModel.catelogPath.length -1 ])
										resetParam()
										ElMessage({type: 'success', message: "添加成功！"})
									});
								}
							}
						cancelBtn();

					} catch (e) {
						e.message
					}
				}).catch(() => {
					ElMessage({type: 'info', message: "添加失败"})
				})
			} else {
				ElMessage.error("提交失败！")

				return false
			}
		})
	}
}
//dialog 取消按钮
const cancelBtn = () => {
	resetParam()
	$emits('attrGroupCancelBtn')
}
//编辑预加载参数回显
const editPreloadData = async (params1) => {
	Object.assign(params, params1)
	let res = await attrGroupIdsearchreq(params);
	Object.assign(attrGroupDataModel, res.data.data)
}

function addFuncrecdata(params2) {
	Object.assign(params, params2)
}

//校验
const rules = reactive<FormRules<typeof attrGroupDataModel>>({
	attrGroupName: [{required: true, message: "品牌名不能为空", trigger: 'blur'}],
	sort: [{
		validator: (rule, value, callback) => {
			if (value == '') {
				callback(new Error("排序字段必须填写！"))
			}
					// else if (!Number.isInteger(value)) {
					// 	callback(new Error("排序必须是正整数"))
			// }
			else {
				callback()
			}
		}, trigger: 'blur'
	}],
	catelogPath: [{
		validator: (rule, value, callback) => {
			if (value == '') {
				callback(new Error("分类ID字段必须选择！"))
			}
					// else if (!Number.isInteger(value)) {
					// 	callback(new Error("分类ID必须是正整数"))
			// }
			else {
				callback()
			}
		}, trigger: 'blur'
	}],
	descript: [{required: true, message: "请填写品牌描述", trigger: "blur"}],
})
//添加函数
const addAttrGroup = async () => {
	try {
		await addAttrGroupreq(attrGroupDataModel)
	} catch (e) {
		e.message
	}
}
//修改函数
const EditAttrGroup = async () => {
	try {
		await EditAttrGroupreq(attrGroupDataModel)
	} catch (e) {
		e.message
	}
}
//logo上传 编辑状态参数回显
const handleLogoUploadSuccess = (res, file) => {
	if (res.code == 200) {
		attrGroupDataModel.icon = res.data
	} else {
		ElMessage.error("请求发送，但没有成功！")
	}
}
const handerLogoUploadError = (res, file) => {
	ElMessage.error("上传失败！" + res.message)
}
const beforeLogoUpload = (file) => {
	let isjpg = file.type === 'image/jpg' || 'image/jpeg'
	let ispng = file.type === 'image/png'
	let israng = file.size / 1024 / 1024 < 2

	if (!isjpg && !ispng) {
		ElMessage.error("图片格式请确保是jpg 或png 格式")
	}
	if (!israng) {
		ElMessage.error("图片大小不得大于2M")
	}
}

//给父组件暴露请求接口
defineExpose({editPreloadData, addFuncrecdata})
//提交添加或修改成功后的清除参数
const resetParam = () => {
			attrGroupDataModel.attrGroupId = 0,
			attrGroupDataModel.attrGroupName = '',
			attrGroupDataModel.icon = '',
			attrGroupDataModel.descript = '',
			attrGroupDataModel.sort = 0,
			attrGroupDataModel.catelogPath = [],
			attrGroupDataModel.catelogId = 0
}

</script>
<style scoped>

</style>