import request from "@/utils/request";
const  BASE_URL  = process.env.VUE_APP_SERVER_PATH


export const changePage = (data) => {
    return request.post(
        "/pms-attr-group/changepage",
        {
            id: data.id,
            pageSize: data.pageSize,
            currentPage: data.currentPage,
            //排序字段
            sidx: data.sidx,
            //排序方式
            order: data.order,
            //关键字
            key: data.key
        })
}
/**
 * 搜索
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const searchByKeyreq = (data) => {
    return request.post(
        "/pms-attr-group/seachBykey",
        {
            pageSize: data.pageSize,
            currentPage: data.currentPage,
            //排序字段
            sidx: data.sidx,
            //排序方式
            order: data.order,
            //关键字
            key: data.key
        }
    )
}
/**
 * 查询编辑回显数据
 * @param reqparam:attrReqParam
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const attrGroupIdsearchreq = (reqparam) => {
    return request.post(
        "/pms-attr-group/searchByattrGroupId",
        {
            id: reqparam.id
        }
    )
}
/**
 * 添加分组
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const addAttrGroupreq = (data) => {
    return request.post(
        "/pms-attr-group/addAttrGroup",
        {
            attrGroupName: data.attrGroupName,
            sort: data.sort,
            descript: data.descript,
            icon: data.icon,
            catelogPath: data.catelogPath,
            catelogId: data.catelogPath[data.catelogPath.length - 1]

        })
}
/**
 * 修改信息提交请求
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
export const EditAttrGroupreq = (data) => {
    return request.post('/pms-attr-group/editAttrGroupByid',
        {
            attrGroupId: data.attrGroupId,
            attrGroupName: data.attrGroupName,
            sort: data.sort,
            descript: data.descript,
            icon: data.icon,
            catelogPath: data.catelogPath,
            catelogId: data.catelogPath[data.catelogPath.length - 1]
        })
}


/**
 * 根据attrGroupId 查询 attr属性信息 : 属性分组关联界面
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getAllOfattrGroupAndAttrRelation_req = (data) => {

    return request.post(
        '/pms-attr-group/getAllOfattrGroupAndAttrRelation',
        {
            id: data.attrGroupId,
            pageSize: data.pageSize,
            currentPage: data.currentPage
        })
}


//获取未分组的属性列表

export const getUnbindAttrList = (data) =>{
 return request.post(
     '/pms-attr-group/getUnbindAttrList',
     {
      id:data.attrGroupId,
      currentPage:data.currentPage,
      pageSize:data.pageSize,
      key:data.key
 })
}


//删除属性分组

export  const deletebatch_req = (data) =>{
    return request.post(
        '/pms-attr-group/deleteAttrGroup',
        {
            ids : data.ids,
            id : data.id
        })
}



//商品发布获取分组列表

export const publish_getgrouplist_req = (data) =>{
    return request.get(`/pms-attr-group/publish_getgrouplist/${data.categoryId}`)
}


