import Cookies from 'js-cookie'


/**
 *
 * @type {string}
 */
const TokenKey = "user-key"
let ExpireTime = new Date(new Date().getTime() + 1200 * 60 *1000)



export function getToken(){

    return Cookies.get(JSON.parse(JSON.stringify(TokenKey)))
}


export function setTokrn(token){
    return Cookies.set(TokenKey,token,{expires:ExpireTime})
}


export function removeToken(){
    return Cookies.remove(TokenKey)
}
