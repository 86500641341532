import request from "@/utils/request";
const  BASE_URL  = process.env.VUE_APP_SERVER_PATH
export const req = () => {
    return request.get('/page')
}
export const page = (currentPage, pageSize) => {
    return request.post(
        '/device/page',
        {
            "currentPage": currentPage.value,
            "pageSize": pageSize.value
        })
}
export const DeviceSearch = (eqmName) => {
    return request.post(
        '/device/Search',
        {
            "searchField": eqmName,

        })
}

export const deviceDetails = (eqmMode) => {
    return request.post(
        '/device/details',
        {
            "eqmMode": eqmMode
        })
}
//手机验证请求
export const reqtelCode = (tel) => {
    return request.post('/user/getCheckCode',
        {
            "phone": tel
        })
}
//手机号登录请求
export const loginByphone = (loginParam) => {
    return request.post(
        '/user/loginByphone',
        {"phone": loginParam.phone,
            "phoneCode": loginParam.phoneCode}
        // {loginParam},
        )
}
//账号登录
export const loginByCount = (loginParam) => {
    return request.post(
        '/user/loginbyCount',
        {
            "name": loginParam.name,
            "password": loginParam.password,

        }
    )
}
//token 认证
export const checkToken = (data) => {
    return request.post(
        '/checkToken',
        {
            "name": data.name,
        },
    {
        headers:{
            token:data.token,
        }
    }
)
}
//用户注册请求
export const registerreq = (registerParam ) => {
    return request.post(
        '/user/register',
        {
            name: registerParam.name,
            nick_name: '',
            password: registerParam.password,
            gender: registerParam.gender,
            avatar: registerParam.avatar,
            phone: registerParam.phone,
            email: registerParam.email,
            token: registerParam.token,
            status: registerParam.status,
            delete: registerParam.delete,
            remark: registerParam.remark,
            creator: registerParam.creator,
            updater: registerParam.updater,
            create_time: registerParam.create_time,
            updata_time: registerParam.updata_time,

        }
    )
}

export const queryUserList = (data)=>{
  return  request.post(
      '/userList',
      {
          data
      }
  )
}
export const queryUserPageList = (currentPage,pageSize)=>{
  return  request.post(
      '/userPageList',
      {
          "currentPage":currentPage.value,
          "pageSize":pageSize.value
      }
  )
}
