<template>
	<div>
		<el-dialog
				align="center"
				:title="useStore.addressDialogTitle"
				@opened="dialogOpend"
				@closed="dialogclosed"
				v-model="useStore.addressDialogShow">
			<el-form
					ref="addrRef"
					:model="addressData"
					:rules="rules"
					status-icon
			>
				<el-form-item label="收件人名称" prop="name" label-width="100px">
					<el-input v-model="addressData.name" placeholder="请输入名称" ></el-input>
				</el-form-item>
				<el-form-item label="手机号码" prop="phone"  label-width="100px">
					<el-input v-model="addressData.phone" placeholder="输入手机号码">
					</el-input>
				</el-form-item>
				<el-form-item label="邮编" prop="postCode"  label-width="100px">
					<el-input v-model="addressData.postCode" placeholder="输入邮政编码">
					</el-input>
				</el-form-item>
				<el-form-item label="选择地址" prop="selectAddr" label-width="100px">
						<el-cascader
								style="width: 100%"
								v-model="addressData.selectAddr"
								placeholder="请选择地址"
								@change="selectChange"
								:props="props"
								:options="options"
						/>
				</el-form-item>
				<el-form-item label="详细地址" prop="detailAddress" label-width="100px">
					<el-input type="textarea" v-model="addressData.detailAddress" placeholder="输入详细地址">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" align="center">
				<el-button @click="dialogCancelBtn">取 消</el-button>
				<el-button type="primary" @click="submit(addrRef)">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script lang="ts" setup>
import userUserStore from "@/stores/user";
import {getCarcascadeList_req,addMemberAddress_req,editMemberAddress_req} from '@/axiosAPI/oms/oms.js'
import {onMounted, ref,reactive,computed} from "vue";
import {FormRules,FormInstance,} from "element-plus";
const defprp = defineProps(['sendData'])
defineOptions({
	name: "add_edit_addressDialog",
	inheritAttrs:false
})
let addrRef = ref<FormInstance>()
let useStore = userUserStore()
onMounted(()=>{
	getCascade()
})
function dialogOpend(){
	if (useStore.addressDialogTitle === "地址编辑界面"){
				let arr = []
				arr.push(defprp.sendData.province)
				arr.push(defprp.sendData.city)
				if (defprp.sendData.town && useStore.addressDialogTitle === "地址编辑界面"){
					arr.push(defprp.sendData.town)
				}else{
					addressData.id = defprp.sendData.id
				}
				addressData.id = defprp.sendData.id
				addressData.memberId=defprp.sendData.memberId,
				addressData.name=defprp.sendData.name,
				addressData.phone=defprp.sendData.phone,
				addressData.province = defprp.sendData.province,
				addressData.city = defprp.sendData.city,
				addressData.town = defprp.sendData.towm,
				addressData.postCode=defprp.sendData.postCode,
				addressData.selectAddr=arr,
				addressData.detailAddress=defprp.sendData.detailAddress
	}
}

function dialogclosed (){
	resetData()
}


const addressData = reactive({
	id:null,
	memberId:'',
	name:'',
	phone:'',
	postCode:'',
	province:'',
	city:'',
	town:'',
	selectAddr:[],
	detailAddress:'',
})
const checkPhone = (rule: any, value: any, callback: any) => {
	if (value === '') {
		callback(new Error("手机号不能为空！"))
	} else if ( value.length!= 11) {
		callback(new Error("手机号格式不正确！"))
	} else {
		callback()
	}
}

const rules = reactive<FormRules>({
	name:[{required:true,message:"请输入收件人姓名",trigger:"blur"}],
	phone:[
			{validator:checkPhone,trigger:'blur'}],
	postCode:[{required:true,message:"请输入邮政编码",trigger:"blur"}],
	selectAddr:[
		{required:true,message:"请选择县/区域",trigger:"blur"},
	],
	detailAddress:[
		{required:true,message:"请输入详细地址",trigger:"blur"},
		{min: 10, message: '请详细输入您的地址，包括乡镇，街道，门牌号等', trigger: 'blur' }]
})

const options =  reactive([])
function getFocus(){
	getCascade()
}
function getCascade(){
	getCarcascadeList()
}


function selectChange(){
	addressData.province =addressData.selectAddr[0]
	addressData.city=addressData.selectAddr[1]
	addressData.town=addressData.selectAddr[2]
}

let props = reactive({
	//要发送的字段
	value: "name",
	label: "name",
	//子集合
	children: "children"
})

async function submit(forE1:FormInstance|undefined){
if (!forE1) return
	await forE1.validate((valid,field)=>{
		if (valid){
			if(useStore.addressDialogTitle === "地址编辑界面"){
				editMemberAddress()
			}
			if (useStore.addressDialogTitle === "地址添加界面"){
				addMemberAddress()
			}
		}else {
		}
	})
}
const emit = defineEmits(['refreshAddress'])


async function editMemberAddress(){
	await editMemberAddress_req(addressData)
	emit('refreshAddress')
	resetData()
	useStore.addressDialogShow = false
}


async function addMemberAddress(){
	addressData.memberId = defprp.sendData
	await addMemberAddress_req(addressData)
	emit('refreshAddress')
	resetData()
	useStore.addressDialogShow = false

}

async function getCarcascadeList(){
 let res = await getCarcascadeList_req()
	Object.assign(options,res.data.data)

}
function dialogCancelBtn(){
	useStore.addressDialogShow = false
}




function resetData(){
		addressData.memberId='',
		addressData.name='',
		addressData.phone='',
		addressData.postCode='',
		addressData.province='',
		addressData.city='',
		addressData.town='',
		addressData.selectAddr=[],
		addressData.detailAddress=''
}


</script>

<style scoped>
.dialog-footer{
	width: 50%;
}

</style>