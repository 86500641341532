import Request from "@/utils/request";


const BASE_URL = process.env.VUE_APP_SERVER_PATH

/**
 * 确认页跳转加载数据
 * @returns {Promise<AxiosResponse<any>>}
 */

export const orderconfirm_req =()=>{
    return Request.post(
         '/oms-order/OrderConfirm'
    )
}

/**
 * 修改地址默认状态
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addrDefaultchange_req = (data)=>{
    return Request.post(
         '/ums-member-address/addrDefaultchange',
        {
            id:data.id,
            defaultStatus:data.defaultStatus

        }
    )
}


/**
 * 获取省市县三级分类
 * @returns {Promise<AxiosResponse<any>>}
 */

export const getCarcascadeList_req =()=>{
    return Request.get(
         '/ums-address-province/getAddressCascader'
    )
}


/**
 * 会员添加地址
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addMemberAddress_req =(data) =>{

    return Request.post(
         '/ums-member-address/addMemberAddress',
        {
            memberId:data.memberId,
            name:data.name,
            phone:data.phone,
            postCode:data.postCode,
            province:data.province,
            city:data.city,
            town:data.town,
            detailAddress: data.detailAddress,
            areaCode:null,
            defaultStatus:0,
            isDelete:0,
            createTime:null,
            updateTime:null,
        }
    )
}


export const editMemberAddress_req =(data) =>{
    return Request.post(
         '/ums-member-address/editMemberAddress',
        {
            id:data.id,
            memberId:data.memberId,
            name:data.name,
            phone:data.phone,
            postCode:data.postCode,
            province:data.province,
            city:data.city,
            town:data.town,
            detailAddress: data.detailAddress,
            areaCode:null,
            defaultStatus:0,
            isDelete:0,
            createTime:null,
            updateTime:null,
        }
    )
}


export const deleteMemberAddress_req = (data) =>{
   return Request.post(
        '/ums-member-address/deleteMmeberAddress',{
           id:data.id,
           memberId:data.memberId,
           name:data.name,
           phone:data.phone,
           postCode:data.postCode,
           province:data.province,
           city:data.city,
           town:data.town,
           detailAddress: data.detailAddress,
           areaCode:null,
           defaultStatus:0,
           isDelete:0,
           createTime:null,
           updateTime:null,
       }
   )
}



export const ordersubmit_req = (data) =>{
    return Request.post(
         '/oms-order/orderSubmit',{
            addrId:data.addrId,
            payType:data.payType,
            orderToken:data.orderToken,
            payPrice:data.payPrice,
            note:data.note
        }
    )
}


export const getOrderList_req = (data) =>{
    return Request.get(
        '/oms-order/OrderList',
        {
            params:{
                currentPage:data.currentPage,
                pageSize:data.pageSize,
                sort:data.sort,
                paymentTime:data.paymentTime,
                status:data.status
            }
        }
    )
}



export const deleteOrder_req =(data) =>{
    return Request.get(
        '/oms-order/deleteOrderbyorderSn',{
            params:{
                orderSn:data
            }
        }
    )
}