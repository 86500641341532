import Axios from "axios";

// Axios.defaults.withCredentials = true
// Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

const request = Axios.create({
    //网络的公共配置
    withCredentials: true,
    baseURL: process.env.VUE_APP_BASE_URL,
    crossDomain: true,
});

//拦截器
request.interceptors.request.use((config) => {
    return config;
})
request.interceptors.response.use((response) => {
    if (response.data.cookie) {
        sessionStorage.setItem('token', response.data.cookie);
    }
    return response;
}, (error) => {
    message.error(error.response.date.message)
    return Promise.reject(error.response.date.message)
})
//发送数据之前
export default request;













