<template>
	<div>
		<span>获取验证码{{time}}s</span>
	</div>
</template>

<script lang="ts" setup>
defineOptions({
	name: "CountDown",
	inheritAttrs:false
})
import {ref,watch} from "vue";
let time = ref(60)
let props = defineProps (['TimerFlag'])
let $emit = defineEmits(['resetFlag'])
watch(
		()=>props.TimerFlag,
		()=>{
			let timmer =	setInterval(()=>{
				time.value --;
				if (time.value == 0) {
					clearInterval(timmer);
					$emit('resetFlag',false)
				}

			},1000)
		},
		{
			//当监听到到值的时候就触发
			immediate:true,
		}
)
//倒计时为0 时通知父组件清空状态
</script>

<style scoped>

</style>