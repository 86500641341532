import Request from "@/utils/request";
import request from "@/utils/request";

const  BASE_URL  = process.env.VUE_APP_SERVER_PATH


/**
 * 获取关系列表
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getrelationData = () =>{
    return Request.get('/pms-attr-attrgroup-relation/getrelationList')
}


/**
 * 属性和分组的关系绑定
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
export const AttrAndAttrGroupRelationBind_req = (data) =>{
    return request.post('/pms-attr-attrgroup-relation/bindAttrAndAttrGroupRelation',
        {
            attrId:data.attrId,
            attrGroupId:data.attrGroupId
        })
}