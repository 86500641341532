import {createApp, onBeforeMount, useSlots} from 'vue'
import App from './App.vue'
//@ts-ignore
import Sigin from './components/login/login_dialog.vue'
import './registerServiceWorker'
import router from './router/index'
import AttrGroup from  './components/Product/common/attrgroup_and_OR_updata.vue'
import bindAttrAndCatelog from './components/Product/common/attrGroupRelationBind.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/permission'
import axios from "axios"
import dataV from '@jiaminghi/data-view'
//国际化组件
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import piniapluginpersist from 'pinia-plugin-persist'
//Dialog组件
import 'vue3-dxui/dxui/dxui.css'
// @ts-ignore
import baiduMap from 'vue3-baidu-map-gl'


import {createPinia} from "pinia";
import userUserStore from '@/stores/user'
window.addEventListener('beforeunload',()=>{
    let userStore = userUserStore()
    if (userStore.userInfo != null){
        userStore.userInfo = null
    }
})
const app = createApp(App)

//一般情况再app创建完成后创建pinia
const pinia = createPinia();
pinia.use(piniapluginpersist)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.config.globalProperties.$axis = axios
app.use(ElementPlus, {locale: zhCn,})
app.use(pinia)
app.use(router)
app.use(dataV)
app.use(baiduMap, {
    ak: 'SELRhfUHNm3W837UMhCXtfGSlLXqlVc6',
    plugins: ['TrackAnimation']
})
// @ts-ignore
app.component(Sigin)
// @ts-ignore
app.component(AttrGroup)
export const globals = app.config.globalProperties
// @ts-ignore
app.component(bindAttrAndCatelog)
app.config.warnHandler = () => null
//读取配置文件


app.mount('#app')























