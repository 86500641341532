<template >
	<div id="app" ref="appBody">
		<router-view ></router-view>
		<memberInfo/>
	</div>
</template>
<script setup>
import memberInfo from '@/pages/member/memberInfo.vue'
import userUserStore from "@/stores/user";
defineOptions({
	name: 'main',
	inheritAttrs: false,
})
const userStore = userUserStore();

</script>


<style scoped>
#app {
	width: 100vw;
	height: 100vh;
	padding: 0;
}


</style>

