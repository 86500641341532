import request from "@/utils/request";


const  BASE_URL  = process.env.VUE_APP_SERVER_PATH

/**
 * 查询会员等级
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const searchMemberLevel_req = (data) =>{
    return request.post(
        '/ums-menber-level/searchMemberLevel',
        {
            id:data.id,
            memberName: data.memberName,
            growthPoint: data.growthPoint,
            defaultStatus: data.defaultStatus,
            freeFreightPoint: data.freeFreightPoint,
            commentGrowthPoint: data.commentGrowthPoint,
            privilegesFreeFreight: data.privilegesFreeFreight,
            privilegesBirthday: data.privilegesBirthday,
            remarks: data.remarks,
            key:data.key,
            currentPage:data.currentPage,
            pageSize:data.pageSize,
        })
}


/**
 * 添加会员
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const addMemberLevel_req = (data) =>{
    return request.post(
        '/ums-menber-level/addMemberLevel',
        {
            memberName: data.memberName,
            growthPoint: data.growthPoint,
            defaultStatus: data.defaultStatus,
            freeFreightPoint: data.freeFreightPoint,
            buyQuantity: data.buyQuantity,
            descountRate: data.descountRate,
            reachPriceReaduce: data.reachPriceReaduce,
            reducePrice: data.reducePrice,
            commentGrowthPoint: data.commentGrowthPoint,
            privilegesFreeFreight: data.privilegesFreeFreight,
            privilegesBirthday: data.privilegesBirthday,
            remarks: data.remarks,
        })
}

/**
 * 编辑会员等级信息
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const editMemberLevel_req = (data) =>{
    return request.post(
        '/ums-menber-level/editMemberLevel',
        {
            id:data.id,
            memberName: data.memberName,
            growthPoint: data.growthPoint,
            defaultStatus: data.defaultStatus,
            freeFreightPoint: data.freeFreightPoint,
            buyQuantity: data.buyQuantity,
            descountRate: data.descountRate,
            reachPriceReaduce: data.reachPriceReaduce,
            reducePrice: data.reducePrice,
            commentGrowthPoint: data.commentGrowthPoint,
            privilegesFreeFreight: data.privilegesFreeFreight,
            privilegesBirthday: data.privilegesBirthday,
            remarks: data.remarks,
        })
}


/**
 * 删除会员等级信息
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const deleteMemberLevel_req = (data) =>{
    return request.post(
        '/ums-menber-level/deleteMemberLevel',
        {
            id:data.id,
            memberName: data.memberName,
            growthPoint: data.growthPoint,
            defaultStatus: data.defaultStatus,
            freeFreightPoint: data.freeFreightPoint,
            commentGrowthPoint: data.commentGrowthPoint,
            privilegesFreeFreight: data.privilegesFreeFreight,
            privilegesBirthday: data.privilegesBirthday,
            remarks: data.remarks,
            key:data.key,
            currentPage:data.currentPage,
            pageSize:data.pageSize,
        })
}


/**
 * 会员注册
 * @param registerParam
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const memberRegister_req = (registerParam ) => {
        return request.post(
            '/ums-member/register',
            {
                    name: registerParam.name,
                    password: registerParam.password,
                    phone: registerParam.phone,
                    code: registerParam.phoneCode
            }
        )
}


export const login_req =(data)=>{
    return request.post(
        '/ums-member/login',
        {
            loginacct: data.loginacct,
            password:data.password
        }
    )
}

export const updataMemberInfo_req= (data) =>{
    return request.post(
         '/ums-member/updataMemberInfo',
        {
            id: data.id,
            token: data.token,
            redirect: data.redirect,
            name: data.name,
            password: data.password,
            phone: data.phone,
            levelId: data.levelId,
            nickName: data.nickName,
            email: data.email,
            header: data.header,
            genders: data.genders,
            birth: data.birth,
            city: data.city,
            job: data.job,
            sign: data.sign,
            sourceType: data.sourceType,
            integreat: data.integreat,
            growth: data.growth,
            status: data.status,
            createTime: data.createTime,
            socialUid: data.socialUid,
            accessToken: data.accessToken,
            expoiresIn: data.expoiresIn,
        }
    )
}
/**
 * 获取会员地址
 */
export const  getAddress_req =(data)=>{
    return request.get(
         '/ums-member-address/getAddress',
        {
            params:{
                memberId:data.memberId
            }
        }
    )
}

/**
 * 查询会员
 *
 */


export const getMemberInfo_req =(data)=>{
    return request.get(
        '/ums-member/getMemberInfo',
        {
            params: {
                pageSize: data.pageSize,
                currentPage: data.currentPage,
                key: data.key
            }
    })
}