import {defineStore} from "pinia";
import {reqtelCode, loginByphone, loginByCount} from "@/axiosAPI/device/device";
import {removeToken} from "@/utils/auth.js";
import {ref} from 'vue'
import {useRoute,useRouter} from "vue-router";


const $route = useRoute()
const $router = useRouter()

export const userUserStore = defineStore('User', {

    state: ()=> {
        return {
            currentLocation: null,
            addressDialogShow:false,
            addressDialogTitle:"",
            userCenterDrawerShow:false,
            orderInfo:null,
            phoneCode:null,
            addressData:{
                id:null,
                memberId:null,
                name:null,
                phone:null,
                postCode:null,
                province:null,
                city:null,
                town:null,
                detailAddress:null,
                areaCode:null,
                defaultStatus:null,
                isDelete:null,
                createTime:null,
                updateTime:null,
            },
            userInfo:{
                token:null,
                redirect:null,
                id:null,
                name:null,
                password:null,
                phone:null,
                levelId:null,
                nickName:null,
                email:null,
                header:null,
                genders:null,
                birth:null,
                city:null,
                job:null,
                sign:null,
                sourceType:null,
                integreat:null,
                growth:null,
                status:null,
                createTime:null,
                socialUid:null,
                accessToken:null,
                expoiresIn:null,
            },
        }
    },

    actions: {
        async getTelCode(phone: string) {
            //正常开发时，只需要发送请求，服务器就能像客户发送信息
            let resulr = await reqtelCode(phone);
            if (resulr.status == 200) {
                this.phoneCode = resulr.date;
            }
        },

        async loginByPhone(loginParam) {
            let result = await loginByphone(loginParam);
            let redirect = $route.query.redirect;
            let data = JSON.stringify(result.data.data)
            localStorage.setItem("userInfo",data)
            let res = null;
            res = JSON.parse(JSON.stringify(localStorage.getItem("userInfo")))
            console.log("res:",res)
            this.userInfo = result.data.data
            if (redirect) {
                $router.push(redirect as string);
            } else {
                $router.push('/frontend');
            }

        },

        async loginByPassword(loginParam) {
            await loginByCount(loginParam).then(({data})=>{
                let res = JSON.stringify(data.data)
                localStorage.setItem("userInfo",res)
                let resData = null;
                resData = JSON.parse(JSON.stringify(localStorage.getItem("userInfo")))
                console.log("res:",resData)
                let redirect = $route.query.redirect;
                if (redirect) {
                    $router.push(redirect as string);
                } else {
                    $router.push('/frontend');
                }
            })
        },

        loginOutSystem() {
            this.userInfo = null
            localStorage.removeItem("userInfo")
            removeToken()
        },


    },

    getters: {},
        persist: {
            enabled: true, // 开启数据缓存 +++++++
        }

})
;
export default userUserStore;


