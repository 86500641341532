<template>
	<!-- 属性分组关联窗口 -->
	<el-dialog :model-value="raltionshipdialogVisible" title="属性分组绑定窗口" :show-close="false" >
		<!-- 头部 -->
		<template #header="{ close, titleId, titleClass }">
			<div class="my-header">

				<div style="display: flex">
					<el-button @click="addrelationship" type="success" style="align-items: center;display: flex">
						新建关系
					</el-button>
					<el-button @click="bacheDelete_Btn" type="danger">
						批量删除
					</el-button>
				</div>
				<h4>属性分组绑定窗口</h4>
				<div></div>
			</div>
		</template>
		<!-- 小弹窗 -->
		<template >
			<el-dialog v-model="innerVisible"
			           width="30%"
			           center
			           append-to-body
			           :show-close="false">
				<template #header="{ close, titleId, titleClass }">
					<div class="my-header">
						<div style="display: flex">
							<el-input v-model="innersearchattrBindkey" placeholder="请输入可选值查询"/>
							<el-button @click="innerattrbindable" type="success" style="align-items: center;display: flex">
								查询
							</el-button>
						</div>
						<div>属性绑定</div>
						<div></div>
					</div>
<!--					<el-table-column align="right">-->
<!--						<template #header>-->
<!--							<el-input v-model="search" size="small" placeholder="Type to search"/>-->
<!--						</template>-->
<!--						<template #default="scope">-->
<!--							<el-button size="small" type="danger" @click="handleunbindBtn(scope.$index, scope.row)">解除关联</el-button>-->
<!--						</template>-->
<!--					</el-table-column>-->
				</template>
							<div>
								<el-table :data="innerAttrTableData" style="width: 100%"
								          @selection-change="innerSelectionChangeofUnbindAttr"
								>
									<el-select></el-select>
									<el-table-column type="selection"/>
									<el-table-column label="属性id" prop="attrId"/>
									<el-table-column label="属性名" prop="attrName"/>
									<el-table-column label="可选值" prop="valueSelect"/>
									<el-table-column label="操作" align="center" size="small">
										<template #default="scope">
											<el-button type="success" @click="bindrelationBtn(scope.$index, scope.row)">绑定</el-button>
										</template>
									</el-table-column>


								</el-table>
								<el-pagination
										v-model:current-page="innercurrentPage"
										v-model:page-size="innerpageSize"
										:page-sizes="[5, 10, 20, 30]"
										layout=" prev, pager, next, jumper,->,total, sizes,"
										:total="innertotal"
										@size-change="innerhandleSizeChange"
										@current-change="innerhandleCurrentChange"
								/>
							</div>
						<template #footer>
					<div class="dialog-footer" style="display: flex;justify-content: space-around">
						<el-button type="danger" @click="innerDialog_cancel">取消</el-button>
						<span><el-button type="primary" @click="innerDialog_submit">确认</el-button> </span>
					</div>
				</template>
			</el-dialog>
		</template>
		<!-- 关联列表 -->
		<div>
			<el-table :data="tableData" style="width: 100%"
			          @selection-change="handleSelectionChange"
			>
				<el-select></el-select>
				<el-table-column type="selection"/>
				<el-table-column label="属性id" prop="attrId"/>
				<el-table-column label="属性名" prop="attrName"/>
				<el-table-column label="可选值" prop="valueSelect"/>
				<el-table-column align="right">
					<template #header>
						<el-input v-model="search" size="small" placeholder="Type to search"/>
					</template>
					<template #default="scope">
						<el-button size="small" type="danger" @click="handleunbindBtn(scope.$index, scope.row)">解除关联</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					v-model:current-page="currentPage"
					v-model:page-size="pageSize"
					:page-sizes="[5, 10, 20, 30]"
					layout=" prev, pager, next, jumper,->,total, sizes,"
					:total="total"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
			/>
		</div>
		<!-- 脚部 -->
		<template #footer>
			<div class="dialog-footer" style="display: flex;justify-content: space-around">
				<el-button type="danger" @click="bindrelation_cancel">取消</el-button>
				<span><el-button type="primary" @click="bindrelation_submit">确定</el-button> </span>
			</div>
		</template>
	</el-dialog>

</template>
<script lang="ts" setup>
import {unBindAttrrelation_req} from '@/axiosAPI/product/attr.js'
import {
	attrGroupIdsearchreq,
	getAllOfattrGroupAndAttrRelation_req,
	getUnbindAttrList
} from '@/axiosAPI/product/attrgroup.js'
import {ref, reactive, computed} from 'vue'
import {AttrAndAttrGroupRelationBind_req} from "@/axiosAPI/product/attrAttrRelation.js";
//***************外部弹窗数据**********************//
let miultDelete = ref([
	{
		attrId: 0,
		attrGroupId: 0
	}
])
let params = {
	id: 0,
	attrId: 0,
	attrGroupId: 0,
	catelogId: 0,
	type: '',
	pageSize: 10,
	currentPage: 1,
	title: '',
	//排序字段
	sidx: '',
	//排序方式
	order: 'asc',
	key: ''
	//关键字
}
defineOptions({
	name: "attrGroupRelationBind",
	inheritAttrs: false
})
//外部弹窗删除请求参数
let deleteParams = reactive([{
	attrId: 0,
	attrGroupId: 0,
}])
let currentPage = ref(1)
let pageSize = ref(5)
let total = ref(0);
let search = ref('')
defineProps(['raltionshipdialogVisible'])
const innerVisible = ref(false)
//内部小窗口分页
let innercurrentPage = ref(1)
let innerpageSize = ref(5)
let innertotal = ref(0)
//分组关系绑定参数对象
let bindRelationParams = {
	attrId:0,
	attrGroupId:0,
}

//小窗口关系绑定
async	function bindrelationBtn (index,row){
	bindRelationParams.attrId = row.attrId;
	bindRelationParams.attrGroupId = params.attrGroupId;

	await AttrAndAttrGroupRelationBind_req(bindRelationParams).then(()=>{
		attrAttrGroupUnbindList(innerparams);
	});
}
//*************内部弹窗数据***************//
//未绑定的属性列表
let innerAttrTableData = ref([{

}])
//查询未关联属性的关键字
let innersearchattrBindkey = ref('')
//未关联属性查询按钮
const innerattrbindable = () => {}
//未关联属性多选事件
const innerSelectionChangeofUnbindAttr = (data) =>{
	bindRelationParams.attrId = params.attrGroupId
	data.map((item)=>
			 bindRelationParams.attrGroupId = item.attrId
		)
	console.log(bindRelationParams)
}
//内部弹窗参数请求数据
let innerparams = {
	attrGroupId:0,
	currentPage:1,
	pageSize:5,
	sidx:0,//排序字段
	order:'',//排序方式
	key:''//关键字
}
//请求数据参数模板
//外部弹窗分页
const handleSizeChange = (V) => {
	params.pageSize = V
	orderbind(params)
}
//外部弹窗分页
const handleCurrentChange = (V) => {
	params.currentPage = V
	orderbind(params)
}
//属性分组解绑按钮
const handleunbindBtn = async (index, row) => {
	let res = {
		attrId: 0,
		attrGroupId: 0
	}
	res.attrId = row.attrId
	res.attrGroupId = params.attrGroupId
	Object.assign(deleteParams, res)
	await unBindAttrrelation_req(deleteParams).then(()=>{
		$emits('refreshArrBindSmallWindow')
		orderbind(params)
	});
}
//内部弹窗分页
const innerhandleSizeChange = (val) =>{
	innerparams.pageSize =  val
	attrAttrGroupUnbindList(innerparams)
}
//内部弹窗分页
const innerhandleCurrentChange = (val) =>{
	innerparams.currentPage = val
	attrAttrGroupUnbindList(innerparams)
}

//取消按钮提交定义
const $emits = defineEmits(['bindrelation_cancel']['refreshArrBindSmallWindow'])
//取消按钮
const bindrelation_cancel = () => {
	$emits('bindrelation_cancel')
	tableData.value = []

}
//弹出框三级下拉菜单数据动态响应数据
const attrGroupCatelogDate = reactive({
	categores: [],
	catelogPath: []
})

//弹出框三级下拉菜单显示属性设置
let props = reactive({
	//要发送的字段
	value: 'id',
	label: 'name',
	//子集合
	children: 'childer'
}) //参数不能定义为数组，否则不显示，只能定义对象

//属性分组绑定按钮（新建关联按钮）
const addrelationship =  () => {
	innerVisible.value = true
	//关键字搜索
	innerparams.key = innersearchattrBindkey.value
	//点击父窗口关联按钮时将分组id传给子组件，子组件解绑和关联属性都会用到这个值。
	innerparams.attrGroupId = params.attrGroupId

	attrAttrGroupUnbindList(innerparams)
}

/**
 * 获取属性和属性分组未关关系列表
 * @param data
 */
async function attrAttrGroupUnbindList(data){
	let res = await getUnbindAttrList(data);
	innerAttrTableData.value = res.data.data
	innertotal.value = res.data.total
}

//外部窗口确定按钮
const bindrelation_submit = () => {
	$emits('bindrelation_cancel')
	tableData.value = []
}

//属性分组批量删除按钮
const bacheDelete_Btn = async () => {
	console.log("deletapar",deleteParams)
	await unBindAttrrelation_req(deleteParams);
}
//属性分组批量删除多选事件
const handleSelectionChange = (Val) => {
	let res = {
		attrIds:[],
		attrGroupId:params.attrGroupId
	}
	 Val.map(item=>{
	 return res.attrIds.push(item.attrId)
	} )
	console.log("res",res)

}

//内部dialog取消按钮
const innerDialog_cancel = () => {
	innerVisible.value = false
	getAllOfattrGroupAndAttrRelation()
}
//内部dialog确定按钮
const innerDialog_submit = () => {
	innerVisible.value = false
	getAllOfattrGroupAndAttrRelation()
}
const attrGroupBindtableData = reactive({})
// 小窗口过滤输入框功能
interface AttrRelation {
	ids: number,
	attrId: number,
	attrName: string,
	valueSelect: string
}
let tableData = ref([])


/**
 * 父组件关系绑定请求
 * @param param
 */
const orderbind = (param) => {
	params = param
	getAllOfattrGroupAndAttrRelation()
}

//获取所有属性和属性分组绑定关系列表
async function getAllOfattrGroupAndAttrRelation(){
	let res = await getAllOfattrGroupAndAttrRelation_req(params);
	tableData.value = res.data.data
}


//向父元素暴露函数
defineExpose({orderbind})

</script>

<style scoped>
.my-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>