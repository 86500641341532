<template>
	<div class="login_container">
		<el-dialog v-model="userStore.visiable" title="用户登录" center width="30%">
					<el-tabs type="border-card">
							<el-tab-pane label="账号注册">
									<div class="login">
											<!--用户账号注册-->
											<el-form ref="ruleFormregisterRef"
											         :model="userParam || companyParam "
											         :rules="userRules || companyRules"
											         status-icon>
												<el-form-item prop="registerrole">
													<el-select v-model="registerrole" placeholder="请选择注册身份">
														<el-option
																v-for="item in roleoptions"
																:key="item.value"
																:label="item.label"
																:value="item.value"
														/>
													</el-select>
												</el-form-item>

												<div v-show="registerrole === 1">
													<el-form-item prop="name">
														<el-input placeholder="请输入用户名" :prefix-icon="User" v-model="userParam.name"></el-input>
													</el-form-item>
													<el-form-item prop="password">
														<el-input type="password" placeholder="请输入密码"
														          :prefix-icon="Lock"
														          show-password
														          v-model="userParam.password"></el-input>
													</el-form-item>
													<el-form-item prop="repassword">
														<el-input type="password" placeholder="请再次输入密码"
														          :prefix-icon="Lock"
														          show-password
														          v-model="userParam.repassword"></el-input>
													</el-form-item>
													<el-form-item prop="gender">
														<el-radio-group v-model="userParam.gender">
															<el-radio :label="1">男</el-radio>
															<el-radio :label="0">女</el-radio>
														</el-radio-group>
													</el-form-item>
													<el-form-item prop="phone">
														<el-input placeholder="请输入注册手机号码" :prefix-icon="Lock"
														          v-model="userParam.phone"></el-input>
													</el-form-item>
													<el-form-item prop="email">
														<el-input placeholder="请输入电子邮箱地址" :prefix-icon="Lock"
														          v-model="userParam.email"></el-input>
													</el-form-item>
													<el-form-item label="头像" prop="avatar"
													              style="height: 100px;width: 100px">
														<el-upload
																class="avatar-uploader"
																ref="userParam.avatar"
																action="#"
																multiple
																:auto-upload="false"
																:on-change="avatarPerView"
																:show-file-list="false">
															<img  v-if="userParam.avatar" alt="#" :src="userParam.avatar" class="avatar"
															      style="height: 50px;width: 50px; border-radius: 50%"/>
															<el-icon
																	v-else class="avatar-uploader-icon"
																	style="height: 100px;width: 100px"
															>
																<Plus/>
															</el-icon>
														</el-upload>
													</el-form-item>
												</div>
												<div v-show="registerrole === 2">
													<el-form-item prop="companyName">
														<el-input placeholder="请输入公司名称" :prefix-icon="User"
														          v-model="companyParamcompanyName"></el-input>
													</el-form-item>
													<el-form-item prop="businessLicenseNumber">
														<el-input placeholder="请输入营业执照号码" :prefix-icon="User"
														          v-model="companyParam.businessLicenseNumber"></el-input>
													</el-form-item>
													<el-form-item prop="industry">
														<el-input placeholder="请输入行业" :prefix-icon="User"
														          v-model="companyParam.industry"></el-input>
													</el-form-item>

													<el-form-item prop="establishmentData">
														<el-input placeholder="请输入成立日期" :prefix-icon="User"
														          v-model="companyParam.establishmentData"></el-input>
													</el-form-item>

													<el-form-item prop="registerCapital">
														<el-input placeholder="请输入注册资本" :prefix-icon="User"
														          v-model="companyParam.registerCapital"></el-input>
													</el-form-item>

													<el-form-item prop="mainBusiness">
														<el-input type="text" placeholder="请输入主营业务" :prefix-icon="User"
														          v-model="companyParam.mainBusiness"></el-input>
													</el-form-item>

													<el-form-item label="身份证正面照" prop="entityIdCardImage1">
														<el-upload
																class="avatar-uploader"
																action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
																:show-file-list="false"
																:on-success="EntityIDCardimg1Success"
																:before-upload="EntityIDCardimg1Upload"
														>
															<img v-if="EntityIDCardimg1Url" :src="EntityIDCardimg1Url" class="avatar"/>
															<el-icon v-else class="avatar-uploader-icon">
																<Plus/>
															</el-icon>
														</el-upload>
													</el-form-item>

													<el-form-item label="身份证反面照" prop="entityIdCardImage2">
														<el-upload
																class="avatar-uploader"
																action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
																:show-file-list="false"
																:on-success="EntityIDCardimg2Success"
																:before-upload="EntityIDCardimg2Upload"
														>
															<img v-if="EntityIDCardimg2Url" :src="EntityIDCardimg2Url" class="avatar"/>
															<el-icon v-else class="avatar-uploader-icon">
																<Plus/>
															</el-icon>
														</el-upload>
													</el-form-item>

													<el-form-item label="营业执照照片" prop="licenceImage">
														<el-upload
																class="avatar-uploader"
																action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
																:show-file-list="false"
																:on-success="businessLicenceimgSuccess"
																:before-upload="businessLicenceimgUpload"
														>
															<img v-if="businessLicenceimgUrl " :src="businessLicenceimgUrl " class="avatar"/>
															<el-icon v-else class="avatar-uploader-icon">
																<Plus/>
															</el-icon>
														</el-upload>
													</el-form-item>

													<el-form-item label="持证正面照" prop="entityCammerImage">
														<el-upload
																class="avatar-uploader"
																action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
																:show-file-list="false"
																:on-success="entityimgSuccess"
																:before-upload="entityimgUpload"
														>
															<img v-if="entityimgUrl" :src="entityimgUrl" class="avatar"/>
															<el-icon v-else class="avatar-uploader-icon">
																<Plus/>
															</el-icon>
														</el-upload>
													</el-form-item>

													<el-form-item label="公司logo" prop="logo">
														<el-upload
																class="avatar-uploader"
																action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
																:show-file-list="false"
																:on-success="businesslogoSuccess"
																:before-upload="businesslogoUpload"
														>
															<img v-if="businesslogoimgUrl" :src="businesslogoimgUrl" class="avatar"/>
															<el-icon v-else class="avatar-uploader-icon">
																<Plus/>
															</el-icon>
														</el-upload>
													</el-form-item>
												</div>

											</el-form>
									</div>
									<div class="botton">
										<el-button style="width: 90%"
										           @click="registerbtn"
										           type="primary" size="default">用户注册
										</el-button>
									</div>
							</el-tab-pane>
							<el-tab-pane label="账号登录">
								<div class="login">
									<!--账号登录-->
									<div v-show="wx_scane === 0">
										<el-form ref="ruleFormRefloginBypassword"
										         :model="loginParamBypassword"
										         :rules="rules_forloginpassword"
										         status-icon>
											<el-form-item prop="name">
												<el-input placeholder="请输入用户名/手机号/电子邮箱地址登录"
												          :prefix-icon="User"
												          v-model="loginParamBypassword.name"></el-input>
											</el-form-item>
											<el-form-item prop="password">
												<el-input placeholder="请输入密码"
												          :prefix-icon="Lock"
												          show-password
												          type="password"
												          v-model="loginParamBypassword.password"
												></el-input>
											</el-form-item>
										</el-form>
										<div class="botton">
											<el-button style="width: 90%"
											           @click="loginByPasswordBtn"
											           :disabled=" !isName || !isPassword "
											           type="primary" size="default">用户登录
											</el-button>
										</div>
									</div>
									<!--微信扫码登录-->
									<div class="webchar" v-show="wx_scane === 1">

									</div>
								</div>
							</el-tab-pane>
							<el-tab-pane label="手机登录">
								<div class="login">
									<!--手机登录-->
									<div v-show="wx_scane === 0">
										<el-form ref="ruleFormRefloginByPhone"
										         :model="loginParamByphone"
										         :rules="rules_forloginphone"
										         status-icon
										>
											<el-form-item prop="phone">
												<el-input placeholder="请输入手机号码"
												          :prefix-icon="User"
												          v-model="loginParamByphone.phone"></el-input>
											</el-form-item>
											<el-form-item prop="phoneCode">
												<el-input placeholder="请输入手机验证码"
												          :prefix-icon="Lock"
												          v-model="loginParamByphone.phoneCode"></el-input>
											</el-form-item>
											<el-form-item>
												<el-button :disabled="!isTel || TimerFlag ? true : false" @click="activeGetCode">


													<!--										<SecondRead v-if="TimerFlag" @resetFlag="resetFlag" :TimerFlag="TimerFlag" />-->
													<CountDown v-if="TimerFlag" @resetFlag="resetFlag" :TimerFlag="TimerFlag"/>
													<!--										<CountDown v-if ="TimerFlag"></CountDown>-->
													<span v-else>获取验证码</span>
												</el-button>

											</el-form-item>
										</el-form>
										<div class="botton">
											<el-button style="width: 90%"
											           @click="loginByPhonebtn"
											           :disabled="!isTel || !loginParamByphone.phoneCode "
											           type="primary" size="default">用户登录
											</el-button>
											<div>
												<p @click="changeScaner">微信扫码登录</p>
											</div>
										</div>
									</div>
									<!--微信扫码登录-->
									<div class="webchar" v-show="wx_scane === 1">

									</div>
								</div>
							</el-tab-pane>
							<el-tab-pane label="找回密码">
								<div class="login">
									<!--邮箱登录-->
									<!--						<div v-show="wx_scane === 0">-->
									<!--							<el-form ref="form" :model="form">-->
									<!--								<el-form-item>-->
									<!--									<el-input placeholder="请输入账号" :prefix-icon="User" v-model="loginParamBypassword.name"></el-input>-->
									<!--								</el-form-item>-->
									<!--								<el-form-item>-->
									<!--									<el-input placeholder="请输入邮箱验证码" :prefix-icon="Lock"-->
									<!--									          v-model="loginParam.emailCode"></el-input>-->
									<!--								</el-form-item>-->
									<!--							</el-form>-->
									<!--							<div class="botton">-->
									<!--								<el-button style="width: 90%"-->
									<!--								           @click="login"-->
									<!--								           :disabled="!isEmail || !loginParam.emailCode "-->
									<!--								           type="primary" size="default">用户登录-->
									<!--								</el-button>-->
									<!--							</div>-->
									<!--						</div>-->
									<!--微信扫码登录-->
									<div class="webchar" v-show="wx_scane === 1">

									</div>
								</div>
							</el-tab-pane>
							<el-tab-pane label="微信登录">微信登录</el-tab-pane>
							<el-tab-pane label="小程序端">
								<div class="right_leftcontent">
									<div class="top">
										<div class="topiteml">
											<img class="codeing" src="/images/icon/user1.png">
											<svg t="1702744732774" class="icon"
											     viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4288" width="32"
											     height="32">
												<path
														d="M337.387283 341.82659c-17.757225 0-35.514451 11.83815-35.514451 29.595375s17.757225 29.595376 35.514451 29.595376 29.595376-11.83815 29.595376-29.595376c0-18.49711-11.83815-29.595376-29.595376-29.595375zM577.849711 513.479769c-11.83815 0-22.936416 12.578035-22.936416 23.6763 0 12.578035 11.83815 23.676301 22.936416 23.676301 17.757225 0 29.595376-11.83815 29.595376-23.676301s-11.83815-23.676301-29.595376-23.6763zM501.641618 401.017341c17.757225 0 29.595376-12.578035 29.595376-29.595376 0-17.757225-11.83815-29.595376-29.595376-29.595375s-35.514451 11.83815-35.51445 29.595375 17.757225 29.595376 35.51445 29.595376zM706.589595 513.479769c-11.83815 0-22.936416 12.578035-22.936416 23.6763 0 12.578035 11.83815 23.676301 22.936416 23.676301 17.757225 0 29.595376-11.83815 29.595376-23.676301s-11.83815-23.676301-29.595376-23.6763z"
														fill="#1afa29" p-id="4289"></path>
												<path
														d="M510.520231 2.959538C228.624277 2.959538 0 231.583815 0 513.479769s228.624277 510.520231 510.520231 510.520231 510.520231-228.624277 510.520231-510.520231-228.624277-510.520231-510.520231-510.520231zM413.595376 644.439306c-29.595376 0-53.271676-5.919075-81.387284-12.578034l-81.387283 41.433526 22.936416-71.768786c-58.450867-41.433526-93.965318-95.445087-93.965317-159.815029 0-113.202312 105.803468-201.988439 233.803468-201.98844 114.682081 0 216.046243 71.028902 236.023121 166.473989-7.398844-0.739884-14.797688-1.479769-22.196532-1.479769-110.982659 1.479769-198.289017 85.086705-198.289017 188.67052 0 17.017341 2.959538 33.294798 7.398844 49.572255-7.398844 0.739884-15.537572 1.479769-22.936416 1.479768z m346.265896 82.867052l17.757225 59.190752-63.630058-35.514451c-22.936416 5.919075-46.612717 11.83815-70.289017 11.83815-111.722543 0-199.768786-76.947977-199.768786-172.393063-0.739884-94.705202 87.306358-171.653179 198.289017-171.65318 105.803468 0 199.028902 77.687861 199.028902 172.393064 0 53.271676-34.774566 100.624277-81.387283 136.138728z"
														fill="#1afa29" p-id="4290"></path>
											</svg>
											<p>微信扫一扫关注</p>
											<p>快速预约</p>
										</div>
										<div class="topitemr">
											<img class="codeing" src="/images/icon/user1.png">
											<svg t="1702744975582" class="icon"
											     viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5597" width="32"
											     height="32">
												<path
														d="M406.109091 251.578182v488.727273a23.272727 23.272727 0 0 0 23.272727 23.272727h302.545455a372.363636 372.363636 0 0 0-325.818182-512z m116.363636 465.454545v-46.545454h186.181818v46.545454z"
														fill="#EDF6FF" p-id="5598"></path>
												<path
														d="M359.563636 739.374545V252.043636a372.363636 372.363636 0 1 0 349.090909 557.149091h-279.272727a69.818182 69.818182 0 0 1-69.818182-69.818182z"
														fill="#EDF6FF" p-id="5599"></path>
												<path
														d="M801.745455 64.465455h-372.363637a69.818182 69.818182 0 0 0-69.818182 69.818181v605.090909a69.818182 69.818182 0 0 0 69.818182 69.818182h372.363637a69.818182 69.818182 0 0 0 69.818181-69.818182v-605.090909a69.818182 69.818182 0 0 0-69.818181-69.818181z m23.272727 674.90909a23.272727 23.272727 0 0 1-23.272727 23.272728h-372.363637a23.272727 23.272727 0 0 1-23.272727-23.272728v-605.090909a23.272727 23.272727 0 0 1 23.272727-23.272727h69.818182a43.752727 43.752727 0 0 0 43.52 42.589091h145.454545a43.752727 43.752727 0 0 0 43.52-42.589091h69.818182a23.272727 23.272727 0 0 1 23.272728 23.272727z"
														fill="#040405" p-id="5600"></path>
												<path d="M522.472727 669.556364h186.181818v46.545454h-186.181818z" fill="#2394FB" p-id="5601">

												</path>
											</svg>
											<p>扫一扫下载</p>
											<p>App下载</p>
										</div>
									</div>
								</div>
							</el-tab-pane>
					</el-tabs>
		</el-dialog>
	</div>
</template>

<script lang="ts" setup>
defineOptions({
	name: "login",
	inheritAttrs: false,

})
import {Lock, User} from '@element-plus/icons-vue'
import userUserStore from "@/stores/user";
import {computed, onMounted, reactive, ref} from "vue";
import CountDown from "@/components/CountDown/CountDown.vue";
import {ElMessage} from "element-plus";
import {registerreq, loginByCount} from "@/axiosAPI/device/device.js";
import type {FormInstance, FormRules,} from 'element-plus'
import {useRouter, useRoute} from "vue-router";


const ruleFormregisterRef = ref<FormInstance>()
const ruleFormRefloginBypassword = ref<FormInstance>()
const ruleFormRefloginByPhone = ref<FormInstance>()

//获取仓库数据（visiable） 可以控制login组件对话框的显示和隐藏
let userStore = userUserStore();
let TimerFlag = ref<boolean>(false);
let $router = useRouter();
//点击登录后检查路由上有没有参数，没有的话往首页跳
let $route = useRoute();

//登录方式控制变量
let wx_scane = ref(0) //0 手机号登录， 1： 微信扫码登录
//切换扫码登录按钮
const changeScaner = () => {
	wx_scane.value = 1;
}
//收集登录表单数据
let loginParamBypassword = reactive({
	name: '',
	password: '',
})
let loginParamByphone = reactive({
	phone: '',
	phoneCode: '',
})
//收集注册参数

let userParam = reactive({
			name: '',
			nick_name: '',
			password: '',
			repassword: '',
			gender: 0,
			avatar: '',
			phone: '',
			email: '',
			token: '',
			status: 0,
			delete: 0,
			remark: '',
			creator: '',
			updater: '',
			create_time: '',
			updata_time: '',
		}
)

let companyParam = reactive({
	companyName: '',
	password: '',
	repassword: '',
	businessEntity: '',
	industry: '',
	creditIndentification: '',
	logo: '',
	strength: '',
	starLevel: '',
	businessLicenseNumber: '',
	deviceModel: '',
	address: '',
	establishmentData: '',
	registerCapital: '',
	mainBusiness: '',
	entityIdCardImage1: '',
	entityIdCardImage2: '',
	entityCammerImage: '',
	licenceImage: ''

})

const validatorRepassword = (rule,value,callback) =>{
	let istrue = computed(() => {
		const reg = /^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/;
		return reg.test(value);
	})
	if (!value) {
		callback(new Error("请输入密码"))
	} else if (!istrue) {
		callback(new Error("字母数字组合的1-16个字符"))
	}else if (value !== userParam.password){
		callback(new Error("两次密码输入不一致"))
	}
	else {
		if (value && istrue && value === userParam.password) {
			callback()
		}
	}
}
const validatorRepasswordc = (rule,value,callback) =>{
	let istrue = computed(() => {
		const reg =  /^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/;
		return reg.test(value);
	})
	if (!value) {
		callback(new Error("请输入密码"))
	} else if (!istrue) {
		callback(new Error("字母数字组合的1-16个字符"))
	}else if (value !== companyParam.password){
		callback(new Error("两次密码输入不一致"))
	}
	else {
		if (value && istrue && value === companyParam.password) {
			callback()
		}
	}
}
const validatorNameU = (rule, value,callback)=>{
	const patten = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
	const limit = /^(?!_)(?![0-9])/
	if (!patten.test(value)) return callback(new Error('只能包含中文、英文字母、数字和下划线'))
	if (!limit.test(value)) return callback(new Error('不能以数字或者下划线开头'))
	callback()
}
const validatorNameC = (rule, value,callback)=>{
	const patten = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
	const limit = /^(?!_)(?![0-9])/
	if (!patten.test(value)) return callback(new Error('只能包含中文、英文字母、数字和下划线'))
	if (!limit.test(value)) return callback(new Error('不能以数字或者下划线开头'))
	callback()
}



//用户注册校验
const userRules = reactive<FormRules<typeof userParam>>({
	name: [{validator:validatorNameU,trigger:'blur'}],
	nick_name: [
		{required: true, message: '请输入用户昵称', trigger: 'change',},
		{min: 2, max: 10, message: '字符保持在在2-10之间', trigger: 'blur'},
	],
	password: [
			{required:true,message:"请输入密码",trigger:"blur"},
			// {validator: validatePassword, trigger: 'blur'},
		{pattern:/^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,9}$/,message:"密码格式不正确",trigger:"blur"}
	],
	repassword: [{validator: validatorRepassword, trigger: 'blur',}],
	gender: [{required: true, message: '请选择性别', trigger: 'change',},],
	avatar: [{required: true, message: '请上传头像', trigger: 'change',},],
	phone: [{required: true, message: '请输入电话号码', trigger: 'change',},],
	email: [{required: true, message: '请输入邮箱', trigger: 'change',},],
})

const companyRules = reactive<FormRules<typeof companyParam>>({
	companyName: [{validator:validatorNameC,trigger:'blur'}],
	password: [
		{required:true,message:"请输入密码",trigger:"blur"},
		// {validator: validatePassword, trigger: 'blur'},
		{pattern:/^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/,message:"字母数字组合的1-16个字符",trigger:"blur"}
	],
	repassword: [
			{validator: validatorRepasswordc, trigger: "change"}
	],
	businessEntity: [{required: true, message: "请输入法人姓名", trigger: "change"}],
	industry: [{required: true, message: "请输入所属行业", trigger: "change"}],
	logo: [{required: true, message: "请上传公司logo", trigger: "change"}],
	businessLicenseNumber: [{required: true, message: "请输入营业执照号码", trigger: "change"}],
	establishmentData: [{required: true, message: "请选择成立日期", trigger: "change"}],
	registerCapital: [{required: true, message: "请输入注册资本", trigger: "change"}],
	mainBusiness: [{required: true, message: "请输入主营业务", trigger: "change"}],
	entityIdCardImage1: [{required: true, message: "请上传身份证正面照", trigger: "change"}],
	entityIdCardImage2: [{required: true, message: "请上传身份证反面照", trigger: "change"}],
	entityCammerImage: [{required: true, message: "请上传法人持证照片", trigger: "change"}],
	licenceImage: [{required: true, message: "请上传营业执照照片", trigger: "change"}]
})

const submitForm = async (formEl: FormInstance | undefined) => {
	if (!formEl) return
	await formEl.validate((valid, fields) => {
		if (valid) {
			console.log('submit!')
		} else {
			console.log('error submit!', fields)
		}
	})
}

const resetForm = (formEl: FormInstance | undefined) => {
	if (!formEl) return
	formEl.resetFields()
}

//密码登录
const checkLoginName = (rule: any, value: any, callback: any) => {
	const patten = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
	const limit = /^(?!_)(?![0-9])/
	if (value == '') return callback(new Error("用户名不能为空"))
	if (!patten.test(value)) return callback(new Error('只能包含中文、英文字母、数字和下划线'))
	if (!limit.test(value)) return callback(new Error('不能以数字或者下划线开头'))
	callback()
}
const checkLoginPassword = (rule: any, value: any, callback: any) => {
	//密码正则，，1个小写字母，1个数字，1-9
	let isP1 = computed(() => {
		const reg = /^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/;

		return reg.test(value)
	})

	if (value === '') {
		callback(new Error("密码不能为空！"))
	} else if (value !== isP1) {
		callback(new Error("字母数字组合的1-16个字符"))
	} else {
		if (value !== '' && isP1) {
			if (ruleFormRefloginBypassword.value) return
			ruleFormRefloginBypassword.value.validateField("checkLoginPassword")
		}
		callback()
	}
}
//验证码登录
const checkLoginPhone = (rule: any, value: any, callback: any) => {
	let isTel = computed(() => {
		//手机号码表达式
		const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
		return reg.test(loginParamByphone.phone);
	})
	if (value === '') {
		callback(new Error("手机号不能为空！"))
	} else if (!isTel) {
		callback(new Error("手机号格式不正确！"))
	} else {
		if (loginParamByphone.phone !== '' && isTel) {
			if (ruleFormRefloginByPhone.value) return
			ruleFormRefloginByPhone.value.validateField("checkLoginPhone")
		}
	}
}
const checkLoginPhoneCode = (rule: any, value: any, callback: any) => {
	if (value === '') {
		callback(new Error("验证码不能为空！"))
	}
	if (value.length !== 6) {
		callback(new Error("验证码格式不正确"))
	}
	if (loginParamByphone.phone !== '' && value.length == 6) {
		if (ruleFormRefloginByPhone.value) return
		ruleFormRefloginByPhone.value.validateField("checkLoginPhoneCode")
	}
}
//注册校验规则

//密码登录表单校验规则
const rules_forloginpassword = reactive<FormRules<typeof loginParamBypassword>>({
	name: [{validator: checkLoginName, trigger: 'blur'}],
	password: [
		{required:true,message:"请输入密码",trigger:"blur"},
		// {validator: validatePassword, trigger: 'blur'},
		{pattern:/^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,9}$/,message:"密码格式不正确",trigger:"blur"}
	],
})
//手机号登录表单校验规则
const rules_forloginphone = reactive<FormRules<typeof loginParamByphone>>({
	phone: [{validator: checkLoginPhone, trigger: 'blur'}],
	phoneCode: [{validator: checkLoginPhoneCode, trigger: 'blur'}],
})
//验证手机号码格式
let isTel = computed(() => {
	//手机号码表达式
	const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
	return reg.test(loginParamByphone.phone);
})
let isRTel = computed(() => {
	//手机号码表达式
	const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
	return reg.test(userParam.phone);
})
//用户名正则，4到16位（字母，数字，下划线，减号）
let isName = computed(() => {
	const patten = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
	const limit = /^(?!_)(?![0-9])/
	return patten.test(loginParamBypassword.name) && limit.test(loginParamBypassword.name)
})
let isRName = computed(() => {
	const reg = /^[a-zA-Z0-9_-]{4,16}$/
	return reg.test(userParam.name)
})
//密码正则，，1个小写字母，1个数字，1-9
let isPassword = computed(() => {
	const reg = /^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/;
	return reg.test(loginParamBypassword.password)
})
let isRPassword = computed(() => {
	const reg = /^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{1,16}$/;
	return reg.test(userParam.password)
})
// 邮箱校验
// let isEmail = computed(() => {
// 	const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
// 	return reg.test(loginParam.email)
// })
let isREmail = computed(() => {
	const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
	return reg.test(userParam.email)
})

//倒计时结束，复位父组件标志
let resetFlag = (val: boolean) => {
	TimerFlag.value = val;
}
//注册请求
let registerbtn = async () => {
	let rt = await registerreq(userParam);
	alert(rt.data.msg)
}
//验证码获取请求
let activeGetCode = async () => {
	TimerFlag.value = true;
	try {
		let rs = await userStore.getTelCode(loginParamByphone.phone);
		loginParamByphone.phoneCode = userStore.phoneCode;
	} catch (e) {
	}
}
//验证码登录操作请求
const loginByPhonebtn = () => {
	userStore.loginByPhone(loginParamByphone);
}
//密码登录操作
let loginByPasswordBtn = () => {
	userStore.loginByPassword(loginParamBypassword);

}
//
const registerrole = ref([''])
const roleoptions = [
	{
		value: 1,
		label: '用户',
	},
	{
		value: 2,
		label: '商家',
	},
	{
		value: 3,
		label: '管理员',
	},
]


import {Plus} from '@element-plus/icons-vue'

import type {UploadProps} from 'element-plus'

//用户头像上传

const userAvatarUpload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpg' || 'image/png') {
		ElMessage.error('Avatar picture must be JPG/PNG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}

const avatarPerView = (uploadFile) =>{
	userParam.avatar = URL.createObjectURL(uploadFile.raw!)
}



//商家身份证正面上传
const EntityIDCardimg1Url = ref('')
const EntityIDCardimg1Success: UploadProps['onSuccess'] = (response, uploadFile) => {
	EntityIDCardimg1Url.value = URL.createObjectURL(uploadFile.raw!)
}
const EntityIDCardimg1Upload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpeg') {
		ElMessage.error('Avatar picture must be JPG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}

//商家身份证反面上传
const EntityIDCardimg2Url = ref('')
const EntityIDCardimg2Success: UploadProps['onSuccess'] = (response, uploadFile) => {
	EntityIDCardimg2Url.value = URL.createObjectURL(uploadFile.raw!)
}
const EntityIDCardimg2Upload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpeg') {
		ElMessage.error('Avatar picture must be JPG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}

//商家营业执照上传
const businessLicenceimgUrl = ref('')
const businessLicenceimgSuccess: UploadProps['onSuccess'] = (response, uploadFile) => {
	businessLicenceimgUrl.value = URL.createObjectURL(uploadFile.raw!)
}
const businessLicenceimgUpload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpeg') {
		ElMessage.error('Avatar picture must be JPG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}

//商家logo上传
const businesslogoimgUrl = ref('')
const businesslogoSuccess: UploadProps['onSuccess'] = (response, uploadFile) => {
	businesslogoimgUrl.value = URL.createObjectURL(uploadFile.raw!)
}
const businesslogoUpload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpeg') {
		ElMessage.error('Avatar picture must be JPG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}

//商家个人真实照片上传
const entityimgUrl = ref('')
const entityimgSuccess: UploadProps['onSuccess'] = (response, uploadFile) => {
	entityimgUrl.value = URL.createObjectURL(uploadFile.raw!)
}
const entityimgUpload: UploadProps['beforeUpload'] = (rawFile) => {
	if (rawFile.type !== 'image/jpeg') {
		ElMessage.error('Avatar picture must be JPG format!')
		return false
	} else if (rawFile.size / 1024 / 1024 > 2) {
		ElMessage.error('Avatar picture size can not exceed 2MB!')
		return false
	}
	return true
}


</script>

<style scoped>
.login_container {
	background: #020202;
	width: 200px;
}

.login {
	border: 1px solid #ccc;
	padding: 20px;
}

::v-deep .el-dialog__body {
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	padding: 0;
}

.botton {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.right_leftcontent {
	margin-left: 20px;
}

.top {
	display: flex;
	justify-content: space-between;
}

.codeing {
	width: 40%;
	height: 40%;
}

.topiteml {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
}

.topitemr {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
}

.avatar-uploader .avatar {
	width: 178px;
	height: 178px;
	display: block;
}


</style>

<style>
.avatar-uploader .el-upload {
	border: 1px dashed var(--el-border-color);
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
	border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	text-align: center;
}
</style>